import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

/**
 * A Redux slice for the WordPress `press` custom post type
 */

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('./appSlice.js').Page} Page A page */

export const pressSlice = createSlice({
  name: 'press',
  initialState: {
    isLoaded: false,
    /** @type {Page[]} */
    list: [],
    error: '',
    currentPageIndex: 1,
  },
  reducers: {
    /**
     * Save the press articles list
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object[]} action.payload  The press articles list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload.map((item) => ({
        ...item,
        data: { ...item.data, color: item.data.color || 'orange' },
      }));
    },

    /**
     * Save the loading error
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current page index
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The current page index
     */
    saveCurrentPageIndex: (state, action) => {
      state.currentPageIndex = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrentPageIndex } = pressSlice.actions;

/**
 * Return the press articles list filtered by a given language
 *
 * @param {string} language  The press article language
 * @returns {object}
 */
export const selectList = createSelector(
  (state) => state.press,
  (_, language) => language,
  (press, language) => press.list.filter((article) => article.lang === language)
);

/**
 * Return the previous press article for a given language
 *
 * @param {number} currentId  The current press article id
 * @param {string} language  The press article language
 * @returns {object}
 */
export const selectPrev = createSelector(
  (state) => state,
  (_, currentId) => currentId,
  (_, __, language) => language,
  (state, currentId, language) => {
    const list = selectList(state, language);
    const currentIndex = list.findIndex((article) => article.id === currentId);
    const prev = list.slice(currentIndex - 1, currentIndex);
    return prev.length > 0 ? prev[0] : null;
  }
);

/**
 * Return the next press article for a given language
 *
 * @param {number} currentId  The current press article id
 * @param {string} language  The press article language
 * @returns {object}
 */
export const selectNext = createSelector(
  (state) => state,
  (_, currentId) => currentId,
  (_, __, language) => language,
  (state, currentId, language) => {
    const list = selectList(state, language);
    const currentIndex = list.findIndex((article) => article.id === currentId);
    const next = list.slice(currentIndex + 1, currentIndex + 2);
    return next.length > 0 ? next[0] : null;
  }
);

/**
 * Return a slice the press articles list filtered by a given language
 *
 * @param {string} language  The press articles language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @returns {object}
 */
export const selectSlice = createSelector(
  (state) => state.press,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (press, language, start, length) =>
    press.list
      .filter((article) => article.lang === language)
      .slice(start || 0, length ? (start || 0) + length : undefined)
);

export default pressSlice.reducer;
