import PropTypes from 'prop-types';
import { useRef, forwardRef } from 'react';
import { useNumberFieldState, useSearchFieldState } from 'react-stately';
import { useField, useTextField, useNumberField, useSearchField, useLocale } from 'react-aria';

/** Components */
import Button from '../../Button';

/**
 * <Field />
 */

export const Field = ({ className, children, ...props }) => {
  const { description, descriptionProps, errorMessage, errorMessageProps, isRequired, isDisabled } = props;

  /** Class names */
  const classNames = ['field'];
  isRequired && classNames.push('is-required');
  isDisabled && classNames.push('is-disabled');
  className && classNames.push(className);

  return (
    <div className={classNames.join(' ')}>
      <div className="field-control">{children}</div>
      {description && (
        <div {...descriptionProps} className="field-description">
          {description}
        </div>
      )}
      {errorMessage && (
        <div {...errorMessageProps} className="field-error">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The field and its label */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /** The description of the field */
  description: PropTypes.string,
  /** The aria props of the description */
  descriptionProps: PropTypes.object,
  /** The error message of the field */
  errorMessage: PropTypes.string,
  /** The aria props of the error message */
  errorMessageProps: PropTypes.object,
  /** Whether the input is required before form submission */
  isRequired: PropTypes.bool,
  /** Whether the input is disabled */
  isDisabled: PropTypes.bool,
};
Field.defaultProps = {
  className: '',
};

/**
 * PropTypes common for all fields
 */
export const fieldPropTypes = {
  /** The label of the field */
  label: PropTypes.string.isRequired,
  /** The description of the field */
  description: PropTypes.string,
  /** The error message of the field */
  errorMessage: PropTypes.string,
  /** Whether the input is disabled */
  isDisabled: PropTypes.bool,
  /** Whether the input can be selected but not changed by the user */
  isReadOnly: PropTypes.bool,
  /** Whether the input is required before form submission */
  isRequired: PropTypes.bool,
  /** Whether the input should display its "valid" or "invalid" visual styling */
  validationState: PropTypes.oneOf(['valid', 'invalid']),
  /** Whether the element should receive focus on render */
  autoFocus: PropTypes.bool,
};

/**
 * <TextField />
 */

export const TextField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;

  const fieldRef = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, fieldRef);

  return (
    <Field
      className="text-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Content is sometimes html */}
      <label {...labelProps} dangerouslySetInnerHTML={{ __html: label }} />
      <input {...inputProps} ref={fieldRef} />
    </Field>
  );
};

TextField.propTypes = {
  ...fieldPropTypes,
  /** The type of the field */
  type: PropTypes.oneOf(['text', 'email', 'url', 'tel', 'password']).isRequired,
};
TextField.defaultProps = {
  type: 'text',
};

/**
 * <TextareaField />
 */

export const TextareaField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;

  const fieldRef = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField({ ...props, inputElementType: 'textarea' }, fieldRef); // prettier-ignore

  return (
    <Field
      className="textarea-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Content is sometimes html */}
      <label {...labelProps} dangerouslySetInnerHTML={{ __html: label }} />
      <textarea {...inputProps} ref={fieldRef} />
    </Field>
  );
};

TextareaField.propTypes = fieldPropTypes;

/**
 * <NumberField />
 */

export const NumberField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;

  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const fieldRef = useRef(null);
  const {
    labelProps,
    groupProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField(props, state, fieldRef);

  return (
    <Field
      className="number-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <label {...labelProps}>{label}</label>
      <div {...groupProps}>
        <Button {...decrementButtonProps}>-</Button>
        <input {...inputProps} ref={fieldRef} />
        <Button {...incrementButtonProps}>+</Button>
      </div>
    </Field>
  );
};

NumberField.propTypes = fieldPropTypes;

/**
 * <FileField />
 */

export const FileField = forwardRef((props, fieldRef) => {
  const { label, description, errorMessage, isRequired, isDisabled, accept } = props;

  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useField(props);

  return (
    <Field
      className="file-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <label {...labelProps}>{label}</label>
      <input
        {...inputProps}
        ref={fieldRef}
        type="file"
        accept={accept || null}
        onChange={(e) =>
          props.onChange({
            name: e.target.files[0].name,
            type: e.target.files[0].type,
            size: e.target.files[0].size,
            blob: URL.createObjectURL(e.target.files[0]),
          })
        }
      />
    </Field>
  );
});

FileField.displayName = 'FileField';
FileField.propTypes = {
  ...fieldPropTypes,
  /** The mime types accepted by the field -- @example "image/*" */
  accept: PropTypes.string,
};

/**
 * <SearchField />
 */

export const SearchField = (props) => {
  const { label, clearButtonLabel, description, errorMessage, isDisabled } = props;

  const fieldRef = useRef();
  const fieldState = useSearchFieldState(props);
  // prettier-ignore
  const { labelProps, inputProps, descriptionProps, errorMessageProps, clearButtonProps } =
    useSearchField(props, fieldState, fieldRef);

  return (
    <Field
      className="search-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isDisabled={isDisabled}
    >
      <label {...labelProps}>{label}</label>
      <input {...inputProps} ref={fieldRef} />
      {fieldState.value !== '' && (
        <Button className="clear-button" {...clearButtonProps} icon="xmark" ariaLabel={clearButtonLabel} />
      )}
    </Field>
  );
};

SearchField.propTypes = {
  ...fieldPropTypes,
  /** The label for the clear button */
  clearButtonLabel: PropTypes.string.isRequired,
};

export default Field;
