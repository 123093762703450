import PropTypes from 'prop-types';
import { useEffect, useRef, useCallback, forwardRef } from 'react';
import { kebabCase } from 'lodash';

/** PropTypes */
import { pagePropTypes, colorPropTypesNoWhite, linkPropTypes } from '../../propTypes';

/** Hooks */
import { useOnLoad } from '../../hooks/useOnLoad';

/** Utils */
import { formatText } from '../../util/String';

/** Components */
import Button from '../Button';
import Link from '../Link';
import Hr from '../Hr';

/**
 * PropTypes
 */
const commonPropTypes = {
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
const commonDefaultProps = {
  className: '',
};

/**
 * <Template />
 */

export const Template = ({ page, layout, className, children }) => {
  process.env.NODE_ENV === 'development' && console.info(`<Template /> - ${page.templateName}`);

  const onLoad = useOnLoad('template-');
  useEffect(() => onLoad());

  /** Class names */
  const classNames = [`template template-${kebabCase(page.templateName)} page-${page.pageName}`];
  layout && classNames.push(`l-${layout}`);
  className && classNames.push(className);

  return (
    <main id="main" className={classNames.join(' ')}>
      {children}
    </main>
  );
};

Template.propTypes = {
  ...commonPropTypes,
  page: PropTypes.shape(pagePropTypes).isRequired,
  /** The layout of the element */
  layout: PropTypes.oneOf(['']),
};

/**
 * <TemplateHeader />
 */

export const TemplateHeader = ({ layout, tag: Tag, titleTag, color, className, data, children }) => {
  const { title, logo, logoLink, year, headline, dates, link, color: colorOverride, loaderColor } = data || {};

  /** Class names */
  const classNames = ['template-header'];
  layout && classNames.push(`l-${layout}`);
  (colorOverride || color) && classNames.push(`bg-${colorOverride || color}`);
  className && classNames.push(className);
  logo && classNames.push('has-logo');
  headline && classNames.push('has-headline');
  link && classNames.push('has-link');

  const Logo = () => <><img src={logo} alt={title.replace(/ [0-9]{4}/, '')} /> {year}</>; // prettier-ignore

  return (
    <Tag className={classNames.join(' ')}>
      {data && title ? (
        <>
          <TemplateTitle tag={titleTag}>
            {logo ? (
              <span>
                {logoLink ? (
                  <Link url={logoLink} loaderColor={loaderColor}>
                    <Logo />
                  </Link>
                ) : (
                  <Logo />
                )}
              </span>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: formatText(title) }} />
            )}
          </TemplateTitle>
          {headline && (
            <TemplateHeadline>
              <div dangerouslySetInnerHTML={{ __html: headline }} />
              {dates && <p dangerouslySetInnerHTML={{ __html: dates }} />}
            </TemplateHeadline>
          )}
          {link && (
            <p className="template-header-link">
              <Link
                layout="button-outline"
                color={(colorOverride && colorOverride !== 'grey') || color !== 'grey' ? 'grey' : 'charcoal'}
                loaderColor={loaderColor}
                url={link.url}
                title={link.title}
                target={link.target}
              />
            </p>
          )}
        </>
      ) : (
        children
      )}
    </Tag>
  );
};

TemplateHeader.propTypes = {
  ...commonPropTypes,
  /** The layout of the element */
  layout: PropTypes.oneOf(['wave']),
  /** The color of the element */
  color: colorPropTypesNoWhite,
  /** The color of the loader for links */
  loaderColor: colorPropTypesNoWhite,
  /** The content of the element */
  data: PropTypes.shape({
    /** The title of the header */
    title: PropTypes.string,
    /** The url of a logo to add to the header */
    logo: PropTypes.string,
    /** The url to a link to add to the logo */
    logoLink: PropTypes.string,
    /** The year to add after the logo  */
    year: PropTypes.number,
    /** The headline of the header */
    headline: PropTypes.string,
    /** Dates to add after the headline */
    dates: PropTypes.string,
    /** A link to add to the header */
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(linkPropTypes)]),
    /** The color of the background of element */
    color: colorPropTypesNoWhite,
    /** The color of the loader for links */
    loaderColor: colorPropTypesNoWhite,
  }),
  /** The tag to apply to the element */
  tag: PropTypes.oneOf(['header', 'div']),
  /** The tag to apply to the <TemplateTitle /> */
  titleTag: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
};
TemplateHeader.defaultProps = {
  tag: 'header',
  data: {},
};

/**
 * <TemplateTitle />
 */

export const TemplateTitle = ({ tag: Tag, className, children }) => (
  <Tag className={`template-title ${className}`}>{children}</Tag>
);

TemplateTitle.propTypes = {
  ...commonPropTypes,
  /** The tag to apply to the element */
  tag: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
};
TemplateTitle.defaultProps = {
  ...commonDefaultProps,
  tag: 'h2',
};

/**
 * <TemplateHeadline />
 */

export const TemplateHeadline = ({ className, children }) => (
  <div className={`template-headline ${className}`}>{children}</div>
);

TemplateHeadline.propTypes = commonPropTypes;
TemplateHeadline.defaultProps = commonDefaultProps;

/**
 * <TemplateContent />
 */

export const TemplateContent = forwardRef(({ className, children }, contentRef) => (
  <div ref={contentRef} className={`template-content ${className}`}>
    {children}
  </div>
));

TemplateContent.displayName = 'TemplateContent';
TemplateContent.propTypes = commonPropTypes;
TemplateContent.defaultProps = commonDefaultProps;

/**
 * <TemplateFooter />
 */

export const TemplateFooter = ({ className, children }) => (
  <footer className={`template-footer ${className}`}>{children}</footer>
);

TemplateFooter.propTypes = commonPropTypes;
TemplateFooter.defaultProps = commonDefaultProps;

/**
 * <TemplateFooterNav />
 */

export const TemplateFooterNav = ({
  article: Article,
  prevProps: { page: prevPage, title: prevTitle },
  nextProps: { page: nextPage, title: nextTitle },
  backProps: { page: backPage, title: backTitle },
}) => {
  /** Hide the articles on click on the body for mobile, as they're shown on click on their respective buttons */
  const prevButtonRef = useRef();
  const nextButtonRef = useRef();
  const hideArticles = useCallback(() => {
    prevButtonRef.current && prevButtonRef.current.classList.remove('is-hover');
    nextButtonRef.current && nextButtonRef.current.classList.remove('is-hover');
  }, []);
  useEffect(() => {
    document.addEventListener('click', hideArticles);
    return () => document.removeEventListener('click', hideArticles);
  }, [hideArticles]);

  return (
    <TemplateFooter className="template-footer">
      <nav className="nav template-footer-nav">
        {prevPage && (
          <div className="nav-prev">
            <h3 className="visually-hidden">{prevTitle}</h3>
            <Button
              ref={prevButtonRef}
              icon="chevron-down-bold"
              onPress={(e) => e.target.classList.toggle('is-hover')}
              ariaHidden
            />
            <Article page={prevPage} />
          </div>
        )}
        {nextPage && (
          <div className="nav-next">
            <h3 className="visually-hidden">{nextTitle}</h3>
            <Button
              ref={nextButtonRef}
              icon="chevron-down-bold"
              onPress={(e) => e.target.classList.toggle('is-hover')}
              ariaHidden
            />
            <Article page={nextPage} />
          </div>
        )}
        {backPage && (
          <div className="nav-back">
            <Hr color="charcoal" />
            <Link layout="button-outline" color="grey" url={backPage.url} title={backTitle} />
          </div>
        )}
      </nav>
    </TemplateFooter>
  );
};

const templateFooterPageProps = PropTypes.shape({
  /** The page */
  page: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  /** The title of the link */
  title: PropTypes.string.isRequired,
});
TemplateFooterNav.propTypes = {
  /** The child article element */
  article: PropTypes.func.isRequired,
  /** The prev button props */
  prevProps: templateFooterPageProps.isRequired,
  /** The next button props */
  nextProps: templateFooterPageProps.isRequired,
  /** The back button props */
  backProps: templateFooterPageProps.isRequired,
};

export default Template;
