import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { selectAvailableFilters as selectAvailableEventsFilters } from './eventsSlice';
import { selectPage } from './pagesSlice';

/**
 * A Redux slice for the WordPress `festivals` custom post type
 */

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('./appSlice.js').Page} Page A page */

export const festivalsSlice = createSlice({
  name: 'festivals',
  initialState: {
    isLoaded: false,
    /** @type {Page[]} */
    list: [],
    error: '',
    currentPageIndex: 1,
    menu: {
      header: { isOpen: false },
      footer: { isOpen: false },
    },
  },
  reducers: {
    /**
     * Save the festivals list
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object[]} action.payload  The festivals list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload.map((item) => ({
        ...item,
        data: { ...item.data, color: item.data.color || 'orange' },
      }));
    },

    /**
     * Save the loading error
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current page index
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The current page index
     */
    saveCurrentPageIndex: (state, action) => {
      state.currentPageIndex = action.payload;
    },

    /**
     * Open / close the festivals menu
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The menu
     * @param {object} action.payload.id  The menu id
     * @param {object} action.payload.isOpen  The menu state
     */
    saveIsMenuOpen: (state, { payload: { id, isOpen }, payload }) => {
      state.menu[id].isOpen = isOpen;
    },
  },
});

export const { saveList, saveError, saveCurrentPageIndex, saveIsMenuOpen } = festivalsSlice.actions;

/**
 * Return the festivals list, not filtered by language
 *
 * @param {boolean} isActive  Whether to return active or archived festivals
 * @returns {object}
 */
export const selectFestivals = createSelector(
  (state) => state.festivals,
  (_, isActive = true) => isActive,
  (festivals, isActive) => festivals.list.filter((festival) => festival.data.isActive === isActive)
);

/**
 * Return the festivals list filtered by a given language
 *
 * @param {string} language  The festivals language
 * @param {boolean} isActive  Whether to return active or archived festivals
 * @returns {object}
 */
export const selectList = createSelector(
  (state) => state.festivals,
  (_, language) => language,
  (_, __, isActive = true) => isActive,
  (festivals, language, isActive) =>
    festivals.list.filter((festival) => festival.lang === language && festival.data.isActive === isActive)
);

/**
 * Return the festival list, after the Redux events filters have been applied.
 *
 * @param {string} language  The festival language
 * @returns {object}  The festival list
 */
export const selectFilteredList = createSelector(
  (state) => state,
  (_, language) => language,
  (state, language) => {
    const availableFilters = selectAvailableEventsFilters(state, 'data.festival', undefined, language);
    return state.festivals.list.filter(
      (festival) => festival.lang === language && availableFilters.includes(festival.id)
    );
  }
);

/**
 * Return a slice the festivals list filtered by a given language
 *
 * @param {string} language  The festivals language
 * @param {boolean} isActive  Whether to return active or archived festivals
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @returns {object}
 */
export const selectSlice = createSelector(
  (state) => state.festivals,
  (_, language) => language,
  (_, __, isActive = true) => isActive,
  (_, __, ___, start) => start,
  (_, __, ___, ____, length) => length,
  (festivals, language, isActive, start, length) =>
    festivals.list
      .filter((festival) => festival.lang === language && festival.data.isActive === isActive)
      .slice(start || 0, length ? (start || 0) + length : undefined)
);

/**
 * Return a festival by its id property.
 *
 * @param {number} festivalId  The festival id
 * @returns {Page}  The festival
 */
export const selectById = createSelector(
  (state) => state.festivals,
  (_, festivalId) => festivalId,
  (festivals, festivalId) => {
    return festivals.list.filter((festival) => festival.id === festivalId)[0];
  }
);

// /**
//  * Return a festival by its slug.
//  *
//  * @param {string} slug  The festival slug
//  * @returns {Page}  The festival
//  */
// export const selectBySlug = createSelector(
//   (state) => state.festivals,
//   (_, language) => language,
//   (_, __, slug) => slug,
//   (festivals, language, slug) => {
//     return festivals.list.filter(
//       (festival) => festival.lang === language && festival.url.split('/').at(-2) === slug
//     )[0];
//   }
// );

/**
 * Return the url for a festival program, based on the festival url, adding the program page slug.
 *
 * @param {string} festivalUrl  The url of the festival
 * @param {string} pageProgramUrl  The url of the program page
 * @returns {string}  The mixed url
 * @example /festival/prendre-soin-bruxelles-2023/le-programme
 * @example /nl/festival/brussels-verzorgen-2023/het-programma
 */
export const getFestivalProgramUrl = (festivalUrl, pageProgramUrl) =>
  `${festivalUrl}${pageProgramUrl.split('/').at(-2)}/`;

/**
 * Return the url for a festival program, based on the festival url, adding the program page slug.
 *
 * @param {string} slug  The festival slug
 * @param {string} language  The festival language
 * @param {Page} festival  The festival
 * @returns {string}  The mixed url
 */
export const selectFestivalProgramUrl = createSelector(
  (state) => state,
  (_, language) => language,
  (_, __, festival) => festival,
  (state, language, festival) => {
    const pageProgram = selectPage(state, 'program', language);
    return festival ? getFestivalProgramUrl(festival.url, pageProgram.url) : pageProgram.url;
  }
);

export default festivalsSlice.reducer;
