import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectHasHistory } from '../../redux/appSlice';
import { selectPage } from '../../redux/pagesSlice';
import { selectFilteredList as selectFilteredEventsList } from '../../redux/eventsSlice';

/** Utils */
import { getLogoByLanguage } from '../../util/Images';

/** Components */
import Link from '../Link';
import FestivalsMenu from '../menus/FestivalsMenu';
import BeeGreen from '../images/BeeGreen';
import logoFR from '../../images/logo-fr-white-color.svg';

const logos = { fr: logoFR };

/**
 * <Footer />
 */

const Footer = (props) => {
  const { t: __, i18n } = useTranslation();

  /** Redux */
  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const pageLegal = useSelector((state) => selectPage(state, 'legal', i18n.language));
  const navPages = [
    useSelector((state) => selectPage(state, 'program', i18n.language)),
    useSelector((state) => selectPage(state, 'create-festival', i18n.language)),
    useSelector((state) => selectPage(state, 'create-event', i18n.language)),
    useSelector((state) => selectPage(state, 'press', i18n.language)),
    useSelector((state) => selectPage(state, 'archives', i18n.language)),
    useSelector((state) => selectPage(state, 'about', i18n.language)),
    useSelector((state) => selectPage(state, 'contact', i18n.language)),
  ];
  const currentPage = useSelector((state) => state.app.currentPage);
  const hasHistory = useSelector((state) => selectHasHistory(state));
  const filteredEventsList = useSelector((state) => selectFilteredEventsList(state, i18n.language));

  /** Class names */
  const classNames = ['layout footer'];
  currentPage.pageName === 'program' &&
    hasHistory &&
    filteredEventsList.length > 0 &&
    classNames.push('visually-hidden');

  return (
    <footer className={classNames.join(' ')}>
      <div className="footer-section">
        <h2 className="logo footer-logo">
          <Link
            className="logo-link"
            url={frontPage.url}
            loaderColor={frontPage.data.color}
            ariaLabel={__('menu.Front page')}
          >
            <img src={getLogoByLanguage(logos, i18n.language)} alt={__('app.siteName')} />
          </Link>
          <span className="visually-hidden">{__('app.siteName')}</span>
        </h2>
        <nav className="nav footer-nav" aria-label={__('menu.Secondary menu')}>
          <ul id="footer-nav-secondary" className="nav-secondary">
            <li>
              <FestivalsMenu id="footer" />
            </li>
            {navPages.map((page, index) => (
              <li key={index}>
                <Link title={page.title} url={page.url} loaderColor={page.data.color} />
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="footer-section">
        <p>
          <span className="nowrap">&copy; {new Date().getFullYear()}</span>{' '}
          <span className="nowrap">{__('app.siteName')}</span>
          <span className="nowrap">
            {' '}
            <span role="separator" /> <Link url="https://arsenic2.org" target="_blank" title="Arsenic2" />
          </span>
          <br />
          <Link title={pageLegal.title} url={pageLegal.url} loaderColor={pageLegal.data.color} />
        </p>
        <p>
          {__('footer.Creation of the website')}&nbsp;:{' '}
          <Link className="beegreen-link" url="https://bee-green.eco" target="_blank">
            <BeeGreen />
            <span className="visually-hidden">Bee Green</span>
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
