import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { colorPropTypesNoWhite } from '../propTypes';

/**
 * <Loader />
 */

const Loader = (props) => {
  process.env.NODE_ENV === 'development' && console.info('<Loader />');

  const { t: __ } = useTranslation();

  const isLoaded = useSelector((state) => state.app.isContentLoaded);
  const color = useSelector((state) => state.app.loaderColor);

  /** Set the loader text */
  useEffect(() => {
    document.querySelector('#loader .loader-text').innerHTML = __('app.Loading');
  }, [__]);

  /** Handle the is-loading / is-loaded class names of the loader & the body */
  useEffect(() => {
    const body = document.querySelector('body');
    const loader = document.getElementById('loader');
    if (!isLoaded) {
      body.classList.add('is-loading');
      loader.classList.remove('is-loaded');
      setTimeout(() => {
        loader.classList.add('is-loading');
      }, 50);
    } else {
      body.classList.remove('is-loading');
      loader.classList.remove('is-loading');
      /** Add a delay to match the css transition in _loader.scss */
      setTimeout(() => {
        loader.classList.add('is-loaded');
      }, 400);
    }
  }, [isLoaded]);

  /** Handle the color of the loader */
  useEffect(() => {
    const loader = document.getElementById('loader');
    const colorMatch = loader.className.match(/[a-z- ]+c-([a-z-]+)/);
    if (!colorMatch || colorMatch[1] !== color) {
      loader.className = `${loader.className.replace(/c-[a-z-]+/, '')} c-${color}`;
    }
  }, [color]);

  return <></>;
};

/**
 * <SectionLoader />
 *
 * This component is to be used within a <Section> layout
 */

export const SectionLoader = ({ color, isLoaded }) => {
  const { t: __ } = useTranslation();
  const [isLoaderRemoved, setIsLoaderRemoved] = useState(false);

  const loader = document.getElementById('loader').cloneNode(true);
  loader.querySelector('.loader-text').innerHTML = __('app.Loading');

  useEffect(() => {
    isLoaded && setTimeout(() => setIsLoaderRemoved(true), 500);
  }, [isLoaded]);

  return (
    <>
      {!isLoaderRemoved ? (
        <div
          className={`loader section-loader ${!isLoaded ? 'is-loading' : ''} c-${color}`}
          dangerouslySetInnerHTML={{ __html: loader.innerHTML }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

SectionLoader.propTypes = {
  color: colorPropTypesNoWhite,
  isLoaded: PropTypes.bool.isRequired,
};
SectionLoader.defaultProps = {
  color: 'orange',
};

export default Loader;
