import PropTypes from 'prop-types';

/** PropTypes */
import { imagePropTypes } from '../propTypes';

/**
 * <Image />
 */

export const Image = ({ layout, className, imgClassName, src, image, format, sizes, alt, onLoad }) => {
  if (typeof src !== 'string' && (typeof image !== 'object' || image[format] === null)) return <></>;
  /**
   * Return the width of the image from the `imagePropTypes` object keys
   *
   * @param {string} size  The image size key
   * @returns {string}  The image size
   * @example "w3840h2560" -> 3840
   */
  const getWidth = (size) => {
    const end = size.indexOf('h');
    return size.substring(1, end > -1 ? end : undefined);
  };

  /** Class names */
  const classNames = { image: ['image'], img: [] };
  className && classNames.image.push(className);
  imgClassName && classNames.img.push(imgClassName);
  layout && classNames.img.push(`l-${layout}`);

  /** If the srcSet contains only one image, set that image as src attribute */
  const srcSetLength = image && format && sizes ? Object.keys(image[format]).length : 0;
  srcSetLength < 2 && image && format && (src = Object.values(image[format])[0]);

  /** Props */
  const srcProps =
    srcSetLength > 1
      ? {
          src: Object.values(image[format]).pop(),
          srcSet: Object.entries(image[format])
            .map(([size, image]) => `${image} ${getWidth(size)}w`)
            .join(','),
          sizes,
        }
      : {
          src,
        };

  return (
    <div className={classNames.image.join(' ')}>
      <img {...srcProps} className={classNames.img.join(' ')} alt={alt} onLoad={onLoad} />
    </div>
  );
};

/**
 * You have to provide either
 * - the src prop
 * - the image, format and sizes props
 */
Image.propTypes = {
  /** The image data */
  image: PropTypes.shape(imagePropTypes),
  /** The layout to apply to the img element */
  layout: PropTypes.oneOf(['rounded']),
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The class names to add to the img element */
  imgClassName: PropTypes.string,
  /** The src of the image */
  src: PropTypes.string,
  /** The format of the image */
  format: PropTypes.oneOf(['landscape', 'portrait', 'square', 'resized', 'logo']),
  /** The sizes attribute for a responsive image */
  /** @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes */
  sizes: PropTypes.string,
  /** The alt attribute */
  alt: PropTypes.string.isRequired,
  /** A callback for the onLoad event */
  onLoad: PropTypes.func,
};
Image.defaultProps = {
  onLoad: () => {},
};

/**
 * Load an image
 *
 * @param {string|imagePropTypes} image  The image string or object
 * @param {string} format  landscape|square  The image format from imagePropTypes
 * @returns {Promise<Event|Error>}  A promise to the image load
 *
 * If you provide an image object, you have to provide the format too
 */
export const loadImage = (image, format) =>
  new Promise((resolve, reject) => {
    const src =
      typeof image === 'string'
        ? image
        : typeof image === 'object' && format !== undefined
        ? Object.values(image[format]).pop()
        : null;

    if (src) {
      const imageLoader = document.createElement('img');
      imageLoader.src = src;
      imageLoader.addEventListener('load', resolve);
    } else {
      return reject('loadImage: src not found');
    }
  });

export default Image;
