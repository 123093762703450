import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { colorPropTypes, colorPropTypesNoWhite } from '../../propTypes';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';
import { selectHasUserFilters, selectHasUserSearch, saveFilter, clearFilters } from '../../redux/eventsSlice';

/** Components */
import Hr from '../Hr';
import Link from '../Link';
import Button from '../Button';

/**
 * <NoResult />
 */

const NoResult = ({ color, buttonColor, ...props }) => {
  const dispatch = useDispatch();
  const { t: __, i18n } = useTranslation();

  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const hasUserSearch = useSelector((state) => selectHasUserSearch(state));
  const hasUserFilters = useSelector((state) => selectHasUserFilters(state, ['data.festival']));

  const [title, buttonTitle] =
    props.title && props.buttonTitle
      ? [props.title, props.buttonTitle]
      : hasUserSearch
      ? [__('template.program.No activity matches your search'), __('button.Clear search')]
      : hasUserFilters
      ? [__('template.program.No activity match the filters you selected'), __('button.Clear filters')]
      : [props.title, __('app.Back to front page')];

  return (
    <div className="no-result">
      <h3 className="h5">{title}</h3>
      <Hr className="l-mb-half" color={color} />
      <p>
        {!props.onPress && !hasUserSearch && !hasUserFilters ? (
          <Link
            layout="button-outline"
            color={buttonColor}
            url={frontPage.url}
            loaderColor={frontPage.data.color}
            title={buttonTitle}
          />
        ) : (
          <Button
            layout="outline"
            color={buttonColor}
            onPress={() => {
              props.onPress
                ? props.onPress()
                : hasUserSearch
                ? dispatch(saveFilter({ filterKey: 'search', values: '' }))
                : dispatch(clearFilters({ except: ['search'] }));
            }}
          >
            {buttonTitle}
          </Button>
        )}
      </p>
    </div>
  );
};

NoResult.propTypes = {
  /** The title of the element */
  title: PropTypes.string.isRequired,
  /** The title of the button */
  buttonTitle: PropTypes.string,
  /** The color to apply to the Hr component */
  color: colorPropTypes,
  /** The color to apply to the Button component */
  buttonColor: colorPropTypesNoWhite,
  /** A callback for the onPress event */
  onPress: PropTypes.func,
};
NoResult.defaultProps = {
  action: 'home',
  color: 'charcoal',
  buttonColor: 'charcoal',
};

export default NoResult;
