import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { get } from 'lodash';

/** Utils */
import { stringToDate, dateTimeStringToDate } from '../util/Date';
import { normalizeString } from '../util/String';

/**
 * A Redux slice for the WordPress `events` custom post type
 */

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('./appSlice.js').Page} Page A page */

/**
 * @typedef {object} DateFilter  The date filter
 * @property {string} startDate  The start date as an ISO 8601 string
 * @property {string} endDate  The end date as an ISO 8601 string
 */

/**
 * @typedef {object} Filters  The event filters
 * @property {number[]} [data_festival=data.festival]  The festival filters
 * @property {string[]} [data_category=data.category]  The category filters
 * @property {string[]} [data_thematic=data.thematic]  The thematic filters
 * @property {string[]} [data_city=data.city]  The city filters
 * @property {DateFilter[]} dates  The date filters
 * @property {DateFilter[]} datesTemp  The temporary date filters
 * @property {string} search  The user search query
 *
 * * The `dateTemp` filter is used while the DateFilter Calendar is open. The filter value is only saved to the `date`
 * * filter when the Calendar overlay is closed. This allows to filter the list while the DateFilter Calendar is open,
 * * without creating a filter instance on each Calendar `onChange` event.
 */

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    isLoaded: false,
    /** @type {Page[]} */
    list: [],
    error: '',
    /**
     * @type {Page|null}
     * * The `current` property is different from the `app.currentPage` one as here we save the current duplicated
     * * event (created by `eventsSlice.saveList`) and not the default `Page` object to allow navigation between the
     * * duplicated events.
     */
    current: null,
    perPage: 36,
    currentPageIndex: 1,
    /** @type {Filters} */
    filters: {
      'data.festival': [],
      'data.category': [],
      'data.thematic': [],
      'data.city': [],
      dates: [],
      datesTemp: [],
      search: '',
    },
    hasFlexibleFilter: false,
    taxonomies: {
      isLoaded: false,
    },
  },
  reducers: {
    /**
     * Save the list
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {Page[]} action.payload  The list
     */
    saveList: (state, action) => {
      const list = createEventsFromDates(action.payload);
      state.list = sortByDateTime(list);
      state.isLoaded = true;
      process.env.NODE_ENV === 'development' && console.info(state.list);
    },

    /**
     * Save the loading error
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current event
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The current event
     */
    saveCurrent: (state, action) => {
      state.current = action.payload;
    },

    /**
     * Save the number of list items to display per page
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The number of items to display per page
     */
    savePerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPageIndex = 1;
    },

    /**
     * Save the current page index
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {number} action.payload  The current page index
     */
    saveCurrentPageIndex: (state, action) => {
      state.currentPageIndex = action.payload;
    },

    /**
     * Clear all the active filters, except those given in the `except` array, if provided.
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {string[]} action.payload.except  The filter that must not be cleared
     */
    clearFilters: (state, action) => {
      state.hasFlexibleFilter = false;
      Object.keys(state.filters).forEach((filterKey) => {
        if (!action.payload || !action.payload.except.includes(filterKey)) {
          state.filters[filterKey] = filterKey !== 'search' ? [] : '';
        }
      });
    },

    /**
     * Save the given filter
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {string} action.payload.filterKey  The filter key
     * @param {string[]|number[]|DateFilter[]} action.payload.values  The filter values
     */
    saveFilter: (state, action) => {
      state.hasFlexibleFilter = false;
      state.filters[action.payload.filterKey] = action.payload.values;
      state.currentPageIndex = 1;
    },

    /**
     * Save the taxonomies
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The taxonomies list
     */
    saveTaxonomies: (state, action) => {
      state.taxonomies = action.payload;
      state.taxonomies.isLoaded = true;
    },

    /**
     * Save the `hasFlexibleFilter` state
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {boolean} action.payload  Whether a filter has been set by flexible content
     */
    saveHasFlexibleFilter: (state, action) => {
      state.hasFlexibleFilter = action.payload;
    },
  },
});

export const {
  saveList,
  saveError,
  saveCurrent,
  savePerPage,
  saveCurrentPageIndex,
  clearFilters,
  saveFilter,
  saveTaxonomies,
  saveHasFlexibleFilter,
} = eventsSlice.actions;

/**
 * Create duplicate of events from their dates array and add the dates and times to its data prop
 *
 * @param {Page[]} list  The events list
 * @returns {Page[]}  The events list
 */
export const createEventsFromDates = (list) => {
  const newList = [];
  list.forEach((item, index) => {
    item.data.dates.forEach(({ startDate, startTime, endDate, endTime }) => {
      newList.push({
        ...item,
        data: { ...item.data, startDate, startTime, endDate, endTime },
      });
    });
  });
  return newList;
};

/**
 * Sort the events list by date and time
 *
 * @param {Page[]} list  The events list
 * @returns {Page}  The sorted list
 */
export const sortByDateTime = (list) =>
  list.sort((a, b) => {
    const aDate = dateTimeStringToDate(`${a.data.startDate} ${a.data.startTime}`);
    const bDate = dateTimeStringToDate(`${b.data.startDate} ${b.data.startTime}`);
    if (aDate.getTime() === bDate.getTime()) {
      return a.title.localeCompare(b.title);
    }
    return aDate > bDate ? 1 : -1;
  });

/**
 * Filter a value from the events list, applying the Redux filters.
 * If listKey is provided, the values are not filtered for the given list key.
 *
 * @param {Filters} filters  The Redux filters object
 * @param {Page} listItem The current list value to filter
 * @param {string} listKey  The list key to use as filter
 * @returns {boolean}  Whether the value passes the filters
 */
export const filterValue = (filters, listItem, listKey) => {
  /**
   * Filter en event by title or promoter, based on the user search query
   *
   * @returns {boolean}  Whether the value passes the filters
   */
  const filterSearch = () => {
    const searchQuery = normalizeString(filters.search).toLowerCase();
    return searchQuery.length > 2
      ? normalizeString(listItem.title).toLowerCase().includes(searchQuery) ||
          normalizeString(listItem.data.promoter).toLowerCase().includes(searchQuery)
      : true;
  };

  /**
   * Filter en event by date, after merging the `startDate` and `startDateTemp` filters
   *
   * @returns {boolean}  Whether the value passes the filters
   */
  const filterDate = () => {
    return [...filters.dates, ...filters.datesTemp].some((value) => {
      const startDate = stringToDate(listItem.data.startDate);
      const endDate = stringToDate(listItem.data.endDate);
      const filterStartDate = stringToDate(value.startDate);
      const filterEndDate = stringToDate(value.endDate);
      return (
        (startDate >= filterStartDate && startDate <= filterEndDate) ||
        (endDate >= filterStartDate && endDate <= filterEndDate) ||
        (startDate <= filterStartDate && endDate >= filterEndDate)
      );
    });
  };

  return Object.entries(filters)
    .map(([filterKey, filterValues]) => {
      const listData = get(listItem, filterKey);
      return filterValues.length === 0 || (listKey && listKey === filterKey) || filterKey === 'search'
        ? filterSearch()
        : filterKey === 'dates' || filterKey === 'datesTemp'
        ? filterDate()
        : Array.isArray(listData)
        ? filterValues.some((value) => listData.includes(value))
        : filterValues.includes(listData);
    })
    .every((value) => value === true);
};

/**
 * Reduce a value from the events list, returning only unique values for the given list key.
 *
 * @param {Array} previousValue  The previously reduced value
 * @param {object} currentValue  The current list value to reduce
 * @param {string} listKey  The list key to use as filter
 * @returns {string[]} The reduced values
 */
const reduceValue = (previousValue, currentValue, listKey) => {
  const listValue = get(currentValue, listKey);
  if (Array.isArray(listValue)) {
    listValue.forEach((value) => {
      previousValue.indexOf(value) === -1 && previousValue.push(value);
    });
  } else if (previousValue.indexOf(listValue) === -1) {
    previousValue.push(listValue);
  }
  return previousValue;
};

/**
 * Sort the values of the list by a given order, by date, festival, or by text content.
 *
 * @param {object|string} a  The value to sort
 * @param {object|string} b  The value to sort
 * @param {string} listKey  The list key
 * @param {string[]} sortOrder  The desired sort order
 * @returns {object}  The sorted values
 */
const sortValues = (a, b, listKey, sortOrder) => {
  if (sortOrder) {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  } else if (listKey === 'startDate' || listKey === 'endDate') {
    return stringToDate(a) > stringToDate(b) ? 1 : -1;
  } else if (listKey === 'data.festival') {
    return a > b ? 1 : -1;
  } else {
    return a.localeCompare(b);
  }
};

/**
 * Filter a value from the events list by a given festival id, if provided.
 *
 * @param {number|undefined} festivalId  The id of the festival
 * @param {Page} listItem The current list value to filter
 * @returns {boolean}  Whether the value passes the filters
 */
export const filterByFestivalId = (festivalId, listItem) =>
  !Number.isInteger(festivalId) || listItem.data.festival === festivalId;

/**
 * Filter all the clones of an event from the list, except the event itself.
 *
 * @param {Page} event  The event
 * @param {Page} listItem  The current list value to filter
 * @returns {boolean}  Whether the value passes the filters
 */
const filterClones = (event, listItem) =>
  (event.id === listItem.id && event.data === listItem.data) || event.id !== listItem.id;

/**
 * Return the events list filtered by a given language
 *
 * @param {string} language  The events language
 * @param {number} festivalId  The id of the festival, if provided, the list will be filtered by festival
 * @returns {object[]}
 */
export const selectList = createSelector(
  (state) => state.events,
  (_, language) => language,
  (_, __, festivalId) => festivalId,
  (events, language, festivalId) =>
    events.list.filter((event) => event.lang === language && filterByFestivalId(festivalId, event))
);

/**
 * Return a slice the events list filtered by a given language
 *
 * @param {string} language  The events language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @param {number} festivalId  The id of the festival, if provided, the list will be filtered by festival
 * @returns {object[]}
 */
export const selectSlice = createSelector(
  (state) => state.events,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (_, __, ___, ____, festivalId) => festivalId,
  (events, language, start, length, festivalId) =>
    events.list
      .filter((event) => event.lang === language && filterByFestivalId(festivalId, event))
      .slice(
        start || (events.currentPageIndex - 1) * events.perPage,
        (start || (events.currentPageIndex - 1) * events.perPage) + (length || events.perPage)
      )
);

/**
 * Return the number of pages the events list.
 *
 * @returns {number}  The number of pages
 */
export const selectNumberPages = createSelector(
  (state) => state.events,
  (_, language) => language,
  (events, language) => Math.ceil(events.list.filter((event) => event.lang === language).length / events.perPage)
);

/**
 * Return the events list, after the Redux filters have been applied.
 *
 * @param {string} language  The events language
 * @returns {object[]}  The events list
 */
export const selectFilteredList = createSelector(
  (state) => state.events,
  (_, language) => language,
  (events, language) => events.list.filter((event) => event.lang === language && filterValue(events.filters, event))
);

/**
 * Return the events list, after the Redux filters have been applied, but without the `dates` one.
 *
 * @param {string} language  The events language
 * @returns {object[]}  The events list
 */
export const selectFilteredListWithoutDateFilter = createSelector(
  (state) => state.events,
  (_, language) => language,
  (events, language) =>
    events.list.filter(
      (event) => event.lang === language && filterValue({ ...events.filters, dates: [], datesTemp: [] }, event)
    )
);

/**
 * Return a slice the events list, after the Redux filters have been applied.
 *
 * @param {string} language  The events language
 * @param {number} start  The start index
 * @param {number} length  The slice length
 * @returns {object[]}  The events list slice
 */
export const selectFilteredSlice = createSelector(
  (state) => state.events,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (events, language, start, length) =>
    events.list
      .filter((event) => event.lang === language && filterValue(events.filters, event))
      .slice(
        start || (events.currentPageIndex - 1) * events.perPage,
        (start || (events.currentPageIndex - 1) * events.perPage) + (length || events.perPage)
      )
);

/**
 * Return the number of pages the events list, after the Redux filters have been applied.
 *
 * @returns {number}  The number of pages
 */
export const selectFilteredNumberPages = createSelector(
  (state) => state.events,
  (_, language) => language,
  (events, language) =>
    Math.ceil(
      events.list.filter((event) => event.lang === language && filterValue(events.filters, event)).length /
        events.perPage
    )
);

/**
 * Return an event by its id property.
 *
 * @param {number} eventId  The event id
 * @returns {object}  The event
 */
export const selectById = createSelector(
  (state) => state.events,
  (_, eventId) => eventId,
  (events, eventId) => {
    return events.list.filter((event) => event.id === eventId)[0];
  }
);

/**
 * Return all the unique values for a given key, with no filter applied
 *
 * @param {string} listKey  The events list key
 * @returns {string[]}  An array of unique values
 */
// export const selectAllFilters = createSelector(
//   (state) => state.events,
//   (_, listKey) => listKey,
//   (events, listKey) =>
//     events.list
//       .reduce((previousValue, currentValue) => reduceValue(previousValue, currentValue, listKey), [])
//       .sort((a, b) => sortValues(a, b, listKey))
// );

/**
 * Return the active filters keys
 *
 * @param {string[]} except  The filter that must not be returned
 * @returns {string[]}  An array of active filters keys
 */
export const selectActiveFilters = createSelector(
  (state) => state.events,
  (_, except) => except,
  (events, except) =>
    Object.entries(events.filters)
      .filter(
        ([filterKey, filterValue]) => filterValue.length > 0 && (!Array.isArray(except) || !except.includes(filterKey))
      )
      .map(([filterKey, filterValue]) => ({ [filterKey]: filterValue }))
);

/**
 * Return all the available unique values of the events list for a given key, after the Redux filters have been applied.
 *
 * @param {string} listKey  The events list key
 * @param {string[]} sortOrder  The desired sort order
 * @returns {string[]}  An array of unique values
 */
export const selectAvailableFilters = createSelector(
  (state) => state.events,
  (_, listKey) => listKey,
  (_, __, sortOrder) => sortOrder,
  (_, __, ___, language) => language,
  (events, listKey, sortOrder, language) =>
    events.list
      .filter((listItem) => listItem.lang === language && filterValue(events.filters, listItem, listKey))
      .reduce((previousValue, currentValue) => reduceValue(previousValue, currentValue, listKey), [])
      .sort((a, b) => sortValues(a, b, listKey, sortOrder))
);

/**
 * Return a slice the events list, filtered by a search query.
 *
 * @param {string} searchQuery  The search query
 * @param {number} start  The start index
 * @param {number} end  The end index
 * @returns {object}  The events list slice
 */
// export const selectSearchedSlice = createSelector(
//   (state) => state.events,
//   (_, searchQuery) => searchQuery,
//   (_, __, start) => start,
//   (_, __, ___, end) => end,
//   (events, searchQuery, start, end) => {
//     searchQuery = normalizeString(searchQuery).toLowerCase();
//     return searchQuery.length > 2
//       ? events.list
//           .filter(
//             (value) =>
//               normalizeString(value.title).toLowerCase().includes(searchQuery) ||
//               normalizeString(value.data.promoter).toLowerCase().includes(searchQuery)
//           )
//           .slice(start, end)
//       : [];
//   }
// );

/**
 * Return whether the user has filters saved.
 *
 * @param {string[]} except  The filter that must not be taken in account
 * @returns {boolean}
 */
export const selectHasUserFilters = createSelector(
  (state) => state.events,
  (_, except) => except,
  (events, except) =>
    Object.entries(events.filters).some(
      ([filterKey, value]) =>
        filterKey !== 'search' && value.length > 0 && (!Array.isArray(except) || !except.includes(filterKey))
    )
);

/**
 * Return whether a user search is saved.
 *
 * @returns {boolean}
 */
export const selectHasUserSearch = createSelector(
  (state) => state.events,
  (events) => events.filters.search.length > 2
);

/**
 * Return the previous event for a given language
 *
 * @param {number} current  The current event
 * @param {string} language  The event language
 * @returns {object}
 */
// export const selectPrev = createSelector(
//   (state) => state,
//   (_, current) => current,
//   (_, __, language) => language,
//   (state, current, language) => {
//     let list = selectList(state, language);
//     list = list.filter((event) => filterClones(event, current));
//     const currentIndex = list.findIndex((event) => event.id === current.id);
//     const prev = list.slice(currentIndex - 1, currentIndex);
//     return prev.length > 0 && list.length > 1 ? prev[0] : null;
//   }
// );

/**
 * Return the next event for a given language
 *
 * @param {number} current  The current event
 * @param {string} language  The event language
 * @returns {object}
 */
// export const selectNext = createSelector(
//   (state) => state,
//   (_, current) => current,
//   (_, __, language) => language,
//   (state, current, language) => {
//     let list = selectList(state, language);
//     list = list.filter((event) => filterClones(event, current));
//     const currentIndex = list.findIndex((event) => event.id === current.id);
//     const next = list.slice(currentIndex + 1, currentIndex + 2);
//     return next.length > 0 && list.length > 1 ? next[0] : null;
//   }
// );

/**
 * Return the previous filtered event for a given language
 *
 * @param {number} current  The current event
 * @param {string} language  The event language
 * @returns {object}
 */
export const selectPrevFiltered = createSelector(
  (state) => state,
  (_, current) => current,
  (_, __, language) => language,
  (state, current, language) => {
    let list = selectFilteredList(state, language);
    list = list.filter((event) => filterClones(event, current));
    const currentIndex = list.findIndex((event) => event.id === current.id);
    const prev = list.slice(currentIndex - 1, currentIndex);
    return prev.length > 0 && list.length > 1 ? prev[0] : null;
  }
);

/**
 * Return the next filtered event for a given language
 *
 * @param {number} current  The current event
 * @param {string} language  The event language
 * @returns {object}
 */
export const selectNextFiltered = createSelector(
  (state) => state,
  (_, current) => current,
  (_, __, language) => language,
  (state, current, language) => {
    let list = selectFilteredList(state, language);
    list = list.filter((event) => filterClones(event, current));
    const currentIndex = list.findIndex((event) => event.id === current.id);
    const next = list.slice(currentIndex + 1, currentIndex + 2);
    return next.length > 0 && list.length > 1 ? next[0] : null;
  }
);

export default eventsSlice.reducer;
