import { useState, useEffect } from 'react';

/** Utils */
import { breakpointDown } from '../util/Breakpoint';

/**
 * Get the responsive image
 *
 * @param {Array|object} image  The image array|object keyed by image sizes
 * @param {string} format (landscape|portrait|square|resized) The image format
 * @returns {string|undefined}  The image url
 */
const getResponsiveImage = (image, format) => {
  let sizes = [];

  /**
   * Get the image in the desired size, fallbacking to smaller images if the desired size does not exist
   *
   * @param {Array|object} image  The image array|object keyed by image sizes
   * @param {string[]} sizes  The existing sizes array for the desired format, sorted from greater to lower sizes
   * @param {string} needle  The desized size
   * @returns {string|undefined}  The image url
   */
  const getSize = (image, sizes, needle) => {
    const sizesSlice = sizes.slice(sizes.findIndex((key) => key === needle));
    for (const size of sizesSlice) {
      if (image[size] !== undefined) {
        return image[size];
      }
    }
    return undefined;
  };

  if (image) {
    switch (format) {
      case 'landscape':
        sizes = ['w3840h2560', 'w2880h1920', 'w1920h1280', 'w1440h960', 'w960h640'];
        if (window.matchMedia(breakpointDown('xs')).matches) {
          return getSize(image, sizes, 'w960h640');
        } else if (window.matchMedia(breakpointDown('sm')).matches) {
          return getSize(image, sizes, 'w1440h960');
        } else if (window.matchMedia(breakpointDown('md')).matches) {
          return getSize(image, sizes, 'w1920h1280');
        } else if (window.matchMedia(breakpointDown('xl')).matches) {
          return getSize(image, sizes, 'w2880h1920');
        } else {
          return getSize(image, sizes, 'w3840h2560');
        }
      case 'portrait':
        sizes = ['w2560h3840', 'w1920h2880', 'w1280h1920', 'w960h1440'];
        if (window.matchMedia(breakpointDown('xs')).matches) {
          return getSize(image, sizes, 'w960h1440');
        } else if (window.matchMedia(breakpointDown('sm')).matches) {
          return getSize(image, sizes, 'w1280h1920');
        } else if (window.matchMedia(breakpointDown('md')).matches) {
          return getSize(image, sizes, 'w1920h2880');
        } else {
          return getSize(image, sizes, 'w2560h3840');
        }
      /**
       * The following cases are not used yet and should be uncommented only if needed
       */
      // case 'square':
      //   sizes = ['w2560h2560', 'w1920h1920', 'w1440h1440', 'w960h960'];
      //   if (window.matchMedia(breakpointDown('xs')).matches) {
      //     return getSize(image, sizes, 'w960h960');
      //   } else if (window.matchMedia(breakpointDown('sm')).matches) {
      //     return getSize(image, sizes, 'w1440h1440');
      //   } else if (window.matchMedia(breakpointDown('md')).matches) {
      //     return getSize(image, sizes, 'w1920h1920');
      //   } else {
      //     return getSize(image, sizes, 'w2560h2560');
      //   }
      // case 'resized':
      //   sizes = ['w3840', 'w2880', 'w1920', 'w1440', 'w960'];
      //   if (window.matchMedia(breakpointDown('xs')).matches) {
      //     return getSize(image, sizes, 'w960');
      //   } else if (window.matchMedia(breakpointDown('sm')).matches) {
      //     return getSize(image, sizes, 'w1440');
      //   } else if (window.matchMedia(breakpointDown('md')).matches) {
      //     return getSize(image, sizes, 'w1920');
      //   } else if (window.matchMedia(breakpointDown('xl')).matches) {
      //     return getSize(image, sizes, 'w2880');
      //   } else {
      //     return getSize(image, sizes, 'w3840');
      //   }
      default:
        break;
    }
  }
};

/**
 * useResponsiveImage Hook
 *
 * Return the url of an image of the given image array regarding window width.
 * The returned image is intended to be used on the full screen width.
 *
 * @param {Array} image  The image array keyed by image sizes
 * @param {string} format (landscape|portrait|square|resized) The image format
 * @returns {string}  The image url
 */
const useResponsiveImage = (image, format) => {
  /**
   * Resize handler
   *
   * @param {CustomEvent|Event} e  The resize event
   */
  const onResize = (e) => {
    if (!window.customResize || e.detail.width) {
      setResponsiveImage(getResponsiveImage(image, format));
    }
  };

  /** Default image */
  const [responsiveImage, setResponsiveImage] = useState(getResponsiveImage(image, format));

  /** Bind/unbind the resize handler */
  useEffect(() => {
    window.addEventListener(window.customResize ? 'custom-resize' : 'resize', onResize);
    return () => window.removeEventListener(window.customResize ? 'custom-resize' : 'resize', onResize);
  });

  /** Return the image url */
  return responsiveImage;
};

export { getResponsiveImage, useResponsiveImage };
export default useResponsiveImage;
