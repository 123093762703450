import PropTypes from 'prop-types';
import { useEffect, useState, lazy, Suspense, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { pagePropTypes } from './propTypes';

/** Redux */
import { saveCurrentPage, saveIsContentLoaded } from './redux/appSlice';
import { saveList as savePagesList } from './redux/pagesSlice';
import { saveList as saveFestivalsList, getFestivalProgramUrl } from './redux/festivalsSlice';
import { saveList as saveEventsList } from './redux/eventsSlice';
import { saveList as savePressList } from './redux/pressSlice';
import { saveOptions } from './redux/optionsSlice';
import { isLogged, saveUser } from './redux/userSlice';

/** Utils */
import { fetchAndParseJSON } from './util/Fetch';
import { getCookie } from './util/Cookies';
import { reduceToObject } from './util/Array';
import { hasProperty } from './util/Object';

/** Hooks */
import { useLanguageDetector } from './hooks/useLanguageDetector';
import { useCustomResize } from './hooks/useCustomResize';
import { useScrollbarWidth } from './hooks/useScrollbarWidth';
import { useMeta } from './hooks/useMeta';
import { useNavigateWithLoader } from './hooks/useNavigateWithLoader';
import { useFetchUserPages } from './hooks/useFetchUserPages';

/** Components */
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Loader from './components/Loader';
import Page404 from './components/templates/Page404';

/** Styles */
import './fonts/icomoon.scss';
import './App.scss';

// TODO: handle loading errors

/**
 * <App />
 */

const App = () => {
  process.env.NODE_ENV === 'development' && console.info('<App />');

  const dispatch = useDispatch();
  const { t: __ } = useTranslation();
  const fetchUserPages = useFetchUserPages();

  useLanguageDetector();
  useCustomResize();
  useScrollbarWidth();

  const isPagesListLoaded = useSelector((state) => state.pages.isLoaded);
  const isUserPagesListLoaded = useSelector((state) => state.user.pages.isLoaded);

  /** Fetch the user data from the user cookie and the user pages if the user is logged */
  useEffect(() => {
    const userCookie = getCookie(process.env.REACT_APP_USER_COOKIE);
    if (userCookie) {
      const user = JSON.parse(userCookie);
      if (user.isLoggedIn) {
        dispatch(saveUser(user));
        !isUserPagesListLoaded && fetchUserPages(user);
      }
    }
  }, [dispatch, fetchUserPages, isUserPagesListLoaded]);

  /** Fetch the pages, festivals, events, press & options */
  useEffect(() => {
    Promise.all([
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}pages`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}festivals`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}events`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}press`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}options`),
    ])
      .then(([pages, festivals, events, press, options]) => {
        dispatch(savePagesList(pages));
        dispatch(saveFestivalsList(festivals));
        dispatch(saveEventsList(events));
        dispatch(savePressList(press));
        dispatch(saveOptions(options));
        return;
      })
      .catch(console.error);
  }, [dispatch]);

  /** Add development tools */
  const DevTools = process.env.NODE_ENV === 'development' ? lazy(() => import('./Dev')) : <></>;

  return (
    <>
      <Loader />
      <BrowserRouter>
        {isPagesListLoaded && (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href="#main" aria-label={__('app.Skip to content')} className="visually-hidden-focusable" />
            <Header />
            <AppRouter />
            <Footer />
          </>
        )}
      </BrowserRouter>
      <Suspense fallback={<></>}>{process.env.NODE_ENV === 'development' && <DevTools />}</Suspense>
    </>
  );
};

/**
 * <AppRouter />
 */

const AppRouter = () => {
  process.env.NODE_ENV === 'development' && console.info('<AppRouter />');

  /** Redux */
  const pagesList = useSelector((state) => state.pages.list);
  const festivalsList = useSelector((state) => state.festivals.list);
  const eventsList = useSelector((state) => state.events.list);
  const pressList = useSelector((state) => state.press.list);
  const userPagesList = useSelector((state) => state.user.pages.list);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const isUserPagesListLoaded = useSelector((state) => state.user.pages.isLoaded);

  /**
   * Create routes for festivals program pages, based on the festival url, adding the program page slug
   *
   * @example /festival/prendre-soin-bruxelles-2023/le-programme
   * @example /nl/festival/brussels-verzorgen-2023/het-programma
   */
  const programList = useMemo(() => {
    const programPages = pagesList.filter((page) => page.pageName === 'program');
    return festivalsList
      .filter((festival) => festival.data.isActive)
      .map((festival) => {
        const programPage = programPages.filter((page) => page.lang === festival.lang)[0];
        /** Create translations for each program page language */
        const translations = reduceToObject(
          Object.entries(programPage.translations).map(([lang, url]) => ({
            /** Return the program page url when there is no translation available for the festival */
            [lang]: hasProperty(festival.translations, lang)
              ? getFestivalProgramUrl(festival.translations[lang], url)
              : url,
          }))
        );
        return {
          ...programPage,
          url: translations[festival.lang],
          translations,
          data: { ...programPage.data, festivalId: festival.id },
        };
      });
  }, [festivalsList, pagesList]);

  const routes = useMemo(
    () => [
      { type: 'page', pages: pagesList },
      { type: 'festival', pages: festivalsList },
      { type: 'event', pages: eventsList },
      { type: 'press', pages: pressList },
      { type: 'program', pages: programList },
      { type: 'user', pages: userPagesList },
    ],
    [pagesList, festivalsList, eventsList, pressList, programList, userPagesList]
  );

  return (
    <Routes>
      {routes.map(({ type, pages }) =>
        pages.map((page, index) => (
          <Route key={`${type}-${index}`} path={page.url} element={<AppRoute page={{ ...page, type }} />} />
        ))
      )}
      {(!isUserLogged || isUserPagesListLoaded) && <Route path="*" element={<Page404 />} />}
    </Routes>
  );
};

/**
 * <AppRoute />
 */

const AppRoute = ({ page }) => {
  const dispatch = useDispatch();
  const setMeta = useMeta();
  const { removeLoader } = useNavigateWithLoader();
  const { i18n } = useTranslation();

  const currentPage = useSelector((state) => state.app.currentPage);
  const [Template, setTemplate] = useState(null);
  const [templateContent, setTemplateContent] = useState(null);
  const [pageContent, setPageContent] = useState(null);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (page !== currentPage) {
      /** Show the loader */
      dispatch(saveIsContentLoaded(false));

      /** Reset the state */
      setTemplate(null);
      setTemplateContent(null);

      /** Lazy load the template */
      setTemplate(lazy(() => import(`./components/templates/${page.templateName}`)));

      /** Fetch the template content */
      fetchAndParseJSON(page.restUrl, {
        'X-APP-LANGUAGE': i18n.language,
        ...(isUserLogged && { 'X-APP-USER': encodeURIComponent(window.btoa(`${user.email}:${user.token}`)) }),
      })
        .then((response) => {
          process.env.NODE_ENV === 'development' && console.info('<AppRoute /> - Page content fetched');

          /** Save the current page to Redux */
          dispatch(saveCurrentPage(page));

          /** Update the state */
          setTemplateContent(response);
          setPageContent(page);

          /** Set the meta data of the page */
          setMeta({ ...response.meta, url: page.url, lang: i18n.language });

          /** Remove the loader */
          removeLoader();
          return;
        })
        .catch(console.error);
    }
  }, [page, currentPage, dispatch, setTemplateContent, setMeta, i18n.language, removeLoader, user, isUserLogged]);

  return (
    <Suspense fallback={<></>}>
      <>{Template && templateContent && <Template page={pageContent} content={templateContent} />}</>
    </Suspense>
  );
};

AppRoute.propTypes = {
  /** The page data */
  page: PropTypes.shape(pagePropTypes).isRequired,
};

export default App;
