import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectHasHistory } from '../../redux/appSlice';
import { selectPage } from '../../redux/pagesSlice';
import { isLogged } from '../../redux/userSlice';

/** Hooks */
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';
import { useOnResize } from '../../hooks/useOnResize';
import { useIsScrolled } from '../../hooks/useIsScrolled';

/** Utils */
import { breakpointUp } from '../../util/Breakpoint';
import { getLogoByLanguage } from '../../util/Images';

/** Components */
import Link from '../Link';
import UserMenu from '../menus/UserMenu';
import LanguageMenu from '../menus/LanguageMenu';
import FestivalsMenu from '../menus/FestivalsMenu';
import logoFR from '../../images/logo-fr-white-color.svg';

const logos = { fr: logoFR };

/**
 * <Header />
 */

const Header = (props) => {
  const { t: __, i18n } = useTranslation();
  const { navigate } = useNavigateWithLoader();
  const isScrolled = useIsScrolled();

  /** Redux */
  const isFrontPage = useSelector((state) => state.app.currentPage.pageName === 'frontpage');
  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const pageProgram = useSelector((state) => selectPage(state, 'program', i18n.language));
  const currentPage = useSelector((state) => state.app.currentPage);
  const hasHistory = useSelector((state) => selectHasHistory(state));
  const isUserLogged = useSelector(isLogged);

  /** Breakpoint under which the mobile menu is displayed - Should match $mobile-breakpoint in _header.scss */
  const mobileBreakpoint = 'sm';

  /** Handle the `isMobile` State */
  const [isMobile, setIsMobile] = useState(!window.matchMedia(breakpointUp(mobileBreakpoint)).matches);
  const handleResize = () => setIsMobile(!window.matchMedia(breakpointUp(mobileBreakpoint)).matches);
  useOnResize(handleResize);
  useEffect(handleResize, []);

  /** Class names */
  const classNames = ['layout header'];
  isFrontPage && classNames.push('is-front-page');
  isScrolled && classNames.push('is-scrolled');
  currentPage.pageName === 'program' && hasHistory && classNames.push('visually-hidden');

  const logoIcon = document.getElementById('loader').querySelector('svg').cloneNode(true);

  return (
    <header className={classNames.join(' ')} role="banner">
      <h1 className="logo header-logo">
        <Link url={frontPage.url} loaderColor={frontPage.data.color} ariaLabel={__('menu.Front page')}>
          {!isMobile ? (
            <img src={getLogoByLanguage(logos, i18n.language)} alt={__('app.siteName')} />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: logoIcon.outerHTML }} />
          )}
        </Link>
        <span className="visually-hidden">{__('app.siteName')}</span>
      </h1>
      <nav className="nav header-nav" aria-label={__('menu.Main menu')}>
        <ul id="header-nav-primary" className={`nav-primary ${isUserLogged ? 'is-logged' : ''}`}>
          {isUserLogged && (
            <li className="nav-user">
              <UserMenu currentPage={currentPage} />
            </li>
          )}
          <li>
            <FestivalsMenu id="header" />
          </li>
          <li>
            <Link url={pageProgram.url} title={pageProgram.title} loaderColor={pageProgram.data.color} />
          </li>
          <li>
            <span className="visually-hidden">{__('menu.Choice of language')}</span>
            {isFrontPage && !isMobile ? (
              <ul className="nav-language">
                {Object.entries(frontPage.translations).map(([language, url], index) => (
                  <li key={index}>
                    <Link
                      className={language === i18n.language ? 'is-active' : ''}
                      url={url}
                      title={language}
                      lang={language}
                      hrefLang={language}
                      onPress={() =>
                        navigate(currentPage.translations[language], currentPage.data.color, () =>
                          i18n.changeLanguage(language)
                        )
                      }
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <div className="nav-language">
                <LanguageMenu currentPage={currentPage} />
              </div>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
