import PropTypes from 'prop-types';

/** PropTypes */
import { colorPropTypes } from '../propTypes';

/**
 * <Hr />
 */

const Hr = ({ layout, className, color }) => (
  <hr className={`hr l-${layout} ${color ? `c-${color}` : ''} ${className}`} />
);

Hr.propTypes = {
  /** The layout of the element */
  layout: PropTypes.oneOf(['heart']),
  /** The class names to add to the element */
  className: PropTypes.string,
  /** The color of the element */
  color: colorPropTypes,
};
Hr.defaultProps = {
  layout: 'heart',
  className: '',
};

export default Hr;
