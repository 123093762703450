import PropTypes from 'prop-types';
import { useRef, useEffect, useState, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectList as selectFestivalsList } from '../../redux/festivalsSlice';
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useOnResize } from '../../hooks/useOnResize';
import { getResponsiveImage } from '../../hooks/useResponsiveImage';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';

/** Utils */
import { getLogoByLanguage } from '../../util/Images';

/** Components */
import Link from '../Link';
import { Image, loadImage } from '../Image';
import logoFR from '../../images/logo-fr-baseline-white-color.svg';

const logos = { fr: logoFR };

/**
 * <Hero />
 */

export const Hero = ({ layout, slideDuration, ...props }) => {
  process.env.NODE_ENV === 'development' && console.info('<Hero />');

  const { t: __, i18n } = useTranslation();
  const { navigate } = useNavigateWithLoader();

  /** Refs */
  const currentBackgroundImageRef = useRef();
  const hoverBackgroundImageRef = useRef();
  const sliderInterval = useRef(null);
  const hoverInterval = useRef(null);
  const hoverIndex = useRef(null);
  const isSliderRunning = useRef(false);
  const screenOrientation = useRef(window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait');

  /** State */
  const [currentFestivalIndex, setCurrentFestivalIndex] = useState(0);
  const [activeLinkIndex, setActiveLinkIndex] = useState(0);
  const [currentBackgroundImage, setCurrentBackgroundImage] = useState();
  const [nextBackgroundImage, setNextBackgroundImage] = useState();
  const [hoverBackgroundImage, setHoverBackgroundImage] = useState();

  /** Redux */
  const festivalslist = useSelector((state) => selectFestivalsList(state, i18n.language, true));
  const pageProgram = useSelector((state) => selectPage(state, 'program', i18n.language));
  const pageCreateFestival = useSelector((state) => selectPage(state, 'create-festival', i18n.language));
  const pageCreateEvent = useSelector((state) => selectPage(state, 'create-event', i18n.language));

  /**
   * Set the current and the next background image
   */
  const setImages = useCallback(() => {
    const nextFestivalIndex = (currentFestivalIndex + 1) % festivalslist.length;
    const currentImage = festivalslist[currentFestivalIndex].data.image;
    const nextImage = festivalslist[nextFestivalIndex].data.image;
    setCurrentBackgroundImage(getResponsiveImage(currentImage[screenOrientation.current], screenOrientation.current));
    setNextBackgroundImage(getResponsiveImage(nextImage[screenOrientation.current], screenOrientation.current));
  }, [currentFestivalIndex, festivalslist]);

  /**
   * Start the slider
   */
  const startSlider = useCallback(() => {
    if (!isSliderRunning.current) {
      isSliderRunning.current = true;
      sliderInterval.current = setInterval(() => {
        const nextFestivalIndex = (currentFestivalIndex + 1) % festivalslist.length;
        currentBackgroundImageRef.current.classList.add('is-fading');
        currentBackgroundImageRef.current.style.opacity = 0;
        /** The active link index is set before the current festival index to synhronize transitions on the logos and backgrounds */
        setActiveLinkIndex(nextFestivalIndex);
        /** Wait the fading transition to be finished before updating the images */
        setTimeout(() => {
          setCurrentFestivalIndex(nextFestivalIndex);
          currentBackgroundImageRef.current.classList.remove('is-fading');
          currentBackgroundImageRef.current.style.opacity = 1;
        }, 333); // Delay should match --hero-fading-duration in _hero.scss
      }, slideDuration);
    }
  }, [currentFestivalIndex, festivalslist.length, slideDuration]);

  /**
   * Stop the slider
   */
  const stopSlider = () => {
    if (isSliderRunning.current) {
      isSliderRunning.current = false;
      clearInterval(sliderInterval.current);
    }
  };

  /**
   * Set the hover background image when the mouse hovers a festival
   *
   * @param {number} index  The index of the festival in the `festivalslist` array
   */
  const setHoverImage = useCallback(
    (index) => {
      hoverIndex.current = index;
      const hoverImage = festivalslist[index].data.image;
      const backgroundImage = getResponsiveImage(hoverImage[screenOrientation.current], screenOrientation.current);
      /** Prevent to unset the background if the mouse is coming from another festival and the fading transition is not finished */
      clearInterval(hoverInterval.current);
      /** Preload the image before setting it as background to let the transition apply */
      loadImage(backgroundImage)
        .then((event) => {
          /** Verify that the mouse is still over the right festival before applying the background */
          if (index === hoverIndex.current) {
            setHoverBackgroundImage(backgroundImage);
            hoverBackgroundImageRef.current.style.opacity = 1;
          }
          return;
        })
        .catch(console.warn);
    },
    [festivalslist, screenOrientation]
  );

  /**
   * Unset the hover background image when the mouse leaves a festival
   */
  const unsetHoverImage = useCallback(() => {
    hoverIndex.current = null;
    hoverBackgroundImageRef.current.style.opacity = 0;
    /** Wait the fading transition to be finished before removing the image */
    hoverInterval.current = setInterval(() => {
      setHoverBackgroundImage(null);
      clearInterval(hoverInterval.current);
    }, 333); // Delay should match --hero-fading-duration in _hero.scss
  }, []);

  /** Handle the responsive background image */
  useOnResize(() => {
    screenOrientation.current = window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait';
    setImages();
  });
  useEffect(setImages, [setImages]);

  /** Initialize the slider */
  useEffect(() => {
    startSlider();
    return () => stopSlider();
  }, [slideDuration, startSlider]);

  /** Handle the slider running state based on whether it is visible (only when the hero is used as app hero) */
  useEffect(() => {
    let intersectionObserver = null;
    let currentBackgroundImage = null;
    if (layout === 'app') {
      currentBackgroundImage = currentBackgroundImageRef.current;
      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          !isSliderRunning.current && startSlider();
        } else {
          isSliderRunning.current && stopSlider();
        }
      });
      intersectionObserver.observe(currentBackgroundImage);
    }
    return () => intersectionObserver && intersectionObserver.unobserve(currentBackgroundImage);
  }, [layout, startSlider]);

  return (
    <div className={`hero l-${layout}`}>
      <div className="hero-background">
        <div
          ref={hoverBackgroundImageRef}
          className="hero-background-hover"
          style={hoverBackgroundImage ? { '--background-image': `url('${hoverBackgroundImage}')` } : null}
        />
        <div
          ref={currentBackgroundImageRef}
          className="hero-background-current"
          style={{ '--background-image': `url('${currentBackgroundImage}')` }}
        />
        <div className="hero-background-next" style={{ '--background-image': `url('${nextBackgroundImage}')` }} />
      </div>
      <h2 className="hero-title visually-hidden">{__('template.front-page.Upcoming festivals')}</h2>
      {layout === 'app' && (
        <img className="hero-logo" src={getLogoByLanguage(logos, i18n.language)} alt={__('app.siteName')} />
      )}
      {layout === 'app' && (
        <Link
          className="hero-program-link"
          layout="button-outline"
          color="grey"
          url={pageProgram.url}
          title={__('button.Detailed program')}
          loaderColor={pageProgram.data.color}
        />
      )}
      <div className="hero-content">
        <ul
          className={`hero-festivals ${
            festivalslist.filter((festival) => festival.data.city.length > 18).length > 0 ? 'has-long-names' : ''
          }`}
        >
          {festivalslist.map((festival, index) => (
            <li key={index}>
              <Link
                className={index === activeLinkIndex ? 'is-active' : ''}
                url={festival.url}
                onPress={() => navigate(festival.url, festival.data.color, props.onClose)}
                onMouseEnter={() => {
                  stopSlider();
                  setHoverImage(index);
                }}
                onMouseLeave={() => {
                  unsetHoverImage();
                  startSlider();
                }}
              >
                <Image src={festival.data.logo} alt={festival.title} />
                <span className="year">{festival.data.year}</span>
              </Link>
            </li>
          ))}
        </ul>
        {layout === 'festivals' && (
          <div className="hero-links">
            <Link
              url={pageCreateFestival.url}
              onPress={() => {
                navigate(pageCreateFestival.url, pageCreateFestival.data.color, props.onClose);
              }}
            >
              <h3 className="h2">{pageCreateFestival.title}</h3>
              {pageCreateFestival.data.menuLabel && <p>{pageCreateFestival.data.menuLabel}</p>}
            </Link>
            <Link
              url={pageCreateEvent.url}
              onPress={() => {
                navigate(pageCreateEvent.url, pageCreateEvent.data.color, props.onClose);
              }}
            >
              <h3 className="h2">{pageCreateEvent.title}</h3>
              {pageCreateEvent.data.menuLabel && <p>{pageCreateEvent.data.menuLabel}</p>}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

Hero.propTypes = {
  /** The layout to apply to the element */
  layout: PropTypes.oneOf(['app', 'festivals']).isRequired,
  /** The duration of a slide in milliseconds */
  slideDuration: PropTypes.number,
  /** A callback for the onClose event */
  onClose: PropTypes.func,
};
Hero.defaultProps = {
  slideDuration: 3333,
  onClose: () => {},
};

export default memo(Hero);
